import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import prod2 from "../../../images/substance.png";
import { Container } from "react-bootstrap";
import HeaderComponent from "../../WebSite/Header/HeaderComponent";
import FooterComponent from "../../WebSite/Footer/FooterComponent";
import ExoploreProductBanner from "../../ExploreProductBanner/ExoploreProductBanner";
import SecondaryNavbar from "../../SecondaryNavbar/SecondaryNavbar";
import { NavHashLink } from "react-router-hash-link";
import ProcessedProducts from "../ProcessedProducts";
import CategoryBanner from "../../CategoryBanner/CategoryBanner";


const AgriServiceMarketplace = () => {
  const [activeCategory, setActiveCategory] = useState("Insurance");
  const [userType] = useState(["FOOD-PROCESSOR"]);

  const titles = {
    "Insurance": "Insurance",
    "AgriAdvisory": "Agri Advisory",
    "SoilTest": "Soil Test",
    "WaterTest": "Water Test",
    "QualityAssaying": "Quality Assaying",
    "WareHousing": "Ware Housing",
    "Spraying/DroneService": "Spraying/Drone Service",
    "FinanceService":"Finance Service"

  };

  const id = {
    "Insurance": "Insurance",
    "AgriAdvisory": "AgriAdvisory",
    "SoilTest": "SoilTest",
    "WaterTest": "WaterTest",
    "QualityAssaying": "QualityAssaying",
    "WareHousing": "WareHousing",
    "Spraying/DroneService": "Spraying/DroneService",
    "FinanceService":"FinanceService"

  };

  const bannerCategory = [
    {
      title: titles["Insurance"],
      image: prod2,
      category: "Insurance",
      id: `#${id["Insurance"]}`,
    },
    {
      title: titles["AgriAdvisory"],
      image: prod2,
      category: "AgriAdvisory",
      id: "#AgriAdvisory",
    },
    {
      title: titles["SoilTest"],
      image: prod2,
      category: "SoilTest",
      id: "#SoilTest",
    },
    {
      title: titles["QualityAssaying"],
      image: prod2,
      category: "QualityAssaying",
      id: "#QualityAssaying",
    },
    {
      title: titles["WareHousing"],
      image: prod2,
      category: "WareHousing",
      id: "#WareHousing",
    },
    {
      title: titles["Spraying/DroneService"],
      image: prod2,
      category: "Spraying/DroneService",
      id: "#Spraying/DroneService",
    }, {
      title: titles["FinanceService"],
      image: prod2,
      category: "FinanceService",
      id: "#FinanceService",
    },

  ];

  const handleCategoryChange = (category) => {
    console.log("Selected Category:", category); // Debug to ensure category change
    setActiveCategory(category);
  };
const categoryImage = {}
  return (
    <>
    <HeaderComponent/>
      {/* <div className="serviceContainer ExploreBannerContainer">
        <div className="gridSection">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
            direction="row"
          >
            {bannerCategory.map(({ title, image, category, id }) => (
              <Grid
                key={category}
                id="complementaryServices_link"
                className="hoverEffect"
                item
                md={3}
                sm={4}
                xs={6}
              >
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    handleCategoryChange(category);
                  }}
                  href={id}
                >
                  <img src={image} alt={title} />
                  <h4>{title}</h4>
                </a>
              </Grid>
            ))}
          </Grid>
        </div>
      </div> */}
<CategoryBanner  services={true} MarketCategoryType="AGR12" categoryImage={categoryImage} quote="quote"/>
      
      <FooterComponent/>
    </>
  );
};

export default AgriServiceMarketplace;
