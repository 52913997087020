import React from "react";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";

export default function FieldSupportServiceSection1() {
  return (
    <div className="subSectionWrapper" id="FieldSupport">
      <h2 className="container-title text-center">Field Support</h2>
      <HomeSubSection
        className="rowReverseAbout borderedCards borderedCards--mod"
        className3="fieldSupportService1"
        description="
        Growlivestock excels in agricultural solutions, offering specialized Asset Relocation Support that encompasses the smooth transfer of colonies and livestock. Our experienced team ensures a seamless process, prioritizing the well-being of assets during relocation for the success of agricultural ventures."
        descriptionTitle="
      Asset Relocation Support"
      />
      <HomeSubSection
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards borderedCards--mod"
        className3="fieldSupportService2"
        descriptionTitle="
        Nutrients & supplements support"
        description="Growlivestock is dedicated to supporting agri-entrepreneur by providing essential supplements and nutrients. Our comprehensive range ensures optimal health for both livestock and plants, empowering agri-entrepreneur to enhance productivity and cultivate thriving agricultural ecosystems. With Growlivestock, agri-entrepreneur have a reliable partner committed to the well-being and success of their agricultural endeavors.
    "
      />
      <HomeSubSection
        className="rowReverseAbout borderedCards borderedCards--mod"
        className3="fieldSupportService3"
        descriptionTitle="
      Buy Back Support"
      description="Growlivestock offers a buy-back service where they provide agri-entrepreneur with the necessary input resources to cultivate specific crops or grow livestock. Once the produce is ready, Growlivestock purchases the produce from the agri-entrepreneur, ensuring a reliable market and income source for them. This mutually beneficial arrangement supports agri-entrepreneur and promotes sustainable agricultural practices."

      />


{/* <HomeSubSection
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards borderedCards--mod"
        className3="fieldSupportService4"
        descriptionTitle="
            Labour Cards"
        description="Health Insurance benefits through Labour card (Social card).
        Growlivestock is committed to empowering agri-entrepreneur by providing information about labor cards offered by the government. Our aim is to ensure that agri-entrepreneur have access to essential details about these cards, helping them make informed decisions and utilize available resources effectively.
    "
      /> */}


{/* <HomeSubSection
        className="rowReverseAbout borderedCards borderedCards--mod"
        className3="fieldSupportService5"
        descriptionTitle="
      Loan"
      description="Growlivestock facilitates a comprehensive range of financial services, including asset loans for agricultural fields and processing assets. Our loan services are designed to provide essential financial support to Agri-Entrepreneur, enabling them to acquire the necessary assets for their operations. Whether you're looking to expand your agricultural holdings or invest in processing equipment, Growlivestock is here to help you secure the capital you need for a prosperous and sustainable future in agriculture."

      /> */}


<HomeSubSection
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards borderedCards--mod"
        className3="fieldSupportService6"
        descriptionTitle="
            Grow"
        description="Growlivestock operates by directly supplying requested assets to agri-entrepreneur. This approach enables agri-entrepreneur to access essential equipment and resources promptly, aligning with their specific needs and ensuring the seamless progression of their agricultural operations. Growlivestock's commitment to serving agri-entrepreneur is rooted in a resource-oriented model, facilitating sustainable growth within the farming community"
      />
<HomeSubSection
        className="rowReverseAbout borderedCards borderedCards--mod"
        className3="fieldSupportService7"
        descriptionTitle="
      Care service for distibuted assets "
      description="Growlivestock specializes in offering care services for distributed assets, including advisory support for asset management, on-demand vet visits for animals, and agronomy services to optimize field productivity. Our comprehensive solutions ensure the well-being and efficiency of your agricultural operations."

      />
<HomeSubSection
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards borderedCards--mod"
        className3="fieldSupportService8"
        descriptionTitle="
        Operator OnField Service"
        description="Operator OnField Service, offered by Growlivestock, delivers essential field support to agricultural operations. With experienced operators skilled in various tasks like machinery operation and maintenance, the service ensures optimal performance and efficiency on the field. Growlivestock's commitment to on-site assistance enhances productivity and minimizes downtime for farmers."
      />
  <HomeSubSection
        className="rowReverseAbout borderedCards borderedCards--mod"
        className3="fieldSupportService9"
        descriptionTitle="
        QA OnField Service"
      description="QA OnField Service, a vital offering from Growlivestock's Field Support services, ensures the highest standards of quality assurance for agricultural operations. With expert quality assurance professionals deployed directly on the field, Growlivestock guarantees meticulous attention to detail, adherence to protocols, and continuous improvement in farming practices. This service strengthens the reliability and consistency of farm output, bolstering the overall success of agricultural endeavors."

      />
<HomeSubSection
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards borderedCards--mod"
        className3="fieldSupportService10"
        descriptionTitle="
        Agent OnField Service"
        description="Agent OnField Service, a cornerstone of Growlivestock's Field Support services, deploys knowledgeable and skilled agents directly to agricultural fields. These agents provide crucial on-site support, offering expertise in various aspects such as crop management, pest control, and soil health. Growlivestock's commitment to on-field assistance through its agents ensures proactive problem-solving and optimal farm performance, contributing to the success and sustainability of agricultural operations."
      />
    </div>
  );
}
